import styled from '@emotion/styled';
import { Button } from '@warbyparker/retail-design-system';
import { OVERFLOW_BUTTON_HEIGHT_PX } from './constants';
import { colors } from '../../../styles/colors';

export const OverflowContainer = styled('div')`
  width: 72px;
  height: ${OVERFLOW_BUTTON_HEIGHT_PX}px;
`;

export const Overlay = styled('div')`
  width: 100%;
  height: 100%;
  background: rgba(64, 74, 85, .2);
  position: absolute;
  top: 0;
  left: 0;
  touch-action: none;
  z-index: 2;
`;

// Why is 'top' -196px or 12px?
//
// If the menu opens below, move it down:
// for MENU_MARGIN_HEIGHT_PX (12px) of the bottom of overflow icon.
//
// If the menu opens above, move it up:
// the height of the overflow icon (48px)
// The height of the MENU_MARGIN_HEIGHT_PX (12px)
// The height of all menu items + gap ((24px + 16px) x number of menu items)
// The height of top padding (16px)
// - (48px + 12px + (24px + 16px) x 3 + 16px) = -196px
type MenuProps = {
  menuTopPx: number;
};
type MenuItemsProps = {
  danger?: boolean;
};
export const Menu = styled('div')<MenuProps>`
  width: 278px;
  background: ${colors.white};
  box-shadow: 0 1px 4px ${colors.charcoalShadow}, 0 8px 12px ${colors.charcoalShadow};
  backdrop-filter: blur(30px);
  border-radius: 12px;
  position: relative;
  left: 0;
  z-index: 3;
  top: ${props => `${props.menuTopPx}px`};
  border-top: 1px solid ${colors.silver};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  font-size: 24px;
`;

export const MenuItem = styled('button')<MenuItemsProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 12px;

  color: ${props => (props.danger ? colors.red : colors.tundora)};
  background: none;
  margin: 0;
  border: 0;
  cursor: pointer;
  justify-content: start;
  touch-action: none;
  font-family: proxima-nova, sans-serif;

  &:disabled {
    background: ${colors.silk};
    color: ${colors.smoke};
  }
`;

export const MenuItemIcon = styled('div')`
  width: 24px;
  height: 24px;
  flex-grow: 0;
  color: ${colors.stone};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuItemText = styled('span')`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.charcoal};
  flex-grow: 1;
`;

export const OverflowButton = styled(Button)`
  width: 62px;
  height: ${OVERFLOW_BUTTON_HEIGHT_PX}px;
  padding: 0;
  font-size: 26px;
`;
