import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Button } from '@warbyparker/retail-design-system';
import { requestFrames, setCurrentPageFrom } from '../../middleware/frame-style-assistant';
import { PAGE_SIZE } from './constants';
import { useAppSelector } from '../../hooks';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
`;

export const Pagination = () => {
  const dispatch = useDispatch();
  const {
    facets,
    pageFrom,
    noMorePages,
    loading,
  } = useAppSelector((state) => state.frameStyleAssistant);

  const loadMore = () => {
    const nextPageFrom = pageFrom + PAGE_SIZE;
    dispatch(setCurrentPageFrom(nextPageFrom));
    dispatch(requestFrames(facets, nextPageFrom));
  };

  return (
    <PaginationContainer>
      { !noMorePages && !loading && (<Button onClick={loadMore} color="quiet">Load More</Button>) }
      { loading && (<Button color="quiet" disabled>Loading...</Button>) }
    </PaginationContainer>
  );
};
