import styled from '@emotion/styled';
import { colors } from 'src/styles/colors';

export const MenuContainer = styled('div')`
  left: 0;
  right: 0;
  row-gap: 20px;
  padding: 16px;
  display: flex;
  position: absolute;
  border-radius: 12px;
  flex-direction: column;
  top: calc(100% + 12px);
  border: solid 1px ${colors.silver};
  box-shadow: 0 8px 12px 0 ${colors.charcoalShadow}, 0px 1px 4px 0px ${colors.charcoalShadow};
  background-color: ${colors.white};
  z-index: 1000;
`;
