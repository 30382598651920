import { SET_IS_MODAL_OPEN } from './actions';
import { ModalsState } from './types';
import { initialState } from './state';

const reducer = (state = initialState, { type, payload }): ModalsState => {
  switch (type) {
    case SET_IS_MODAL_OPEN:
      return {
        ...state,
        [payload.type]: {
          isOpen: payload.isOpen,
          modalAction: payload.modalAction,
          customProps: payload.customProps,
        },
      };
    default:
      return state;
  }
};

export default {
  reducer,
};
