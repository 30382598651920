import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { splitAndCapitalize } from 'src/utils/formatString';
import { APPOINTMENT } from 'src/constants';
import { updateAppointmentData } from 'src/middleware/eye-exams';
import { formatDoctorName } from 'src/utils/appointments';
import FeatureFlagged from 'src/components/feature-flagged';
import { CONFIRM_EYE_EXAM_BOOKING_FEATURE_FLAG, EYE_EXAM_TOOLING_INTAKE_FORM } from 'feature-flags';
import { ModalContainerRef } from 'src/components/popover-modal';
import AppointmentOverflowMenu from '../appointment-overflow-menu/appointment-overflow-menu';
import { RemindIntakeFormModal } from '../remind-intake-form-modal';
import ConfirmedTag from '../../confirmed-tag/confirmed-tag';
import { useAppSelector } from '../../../hooks';

import * as Styled from './styles';

const AppointmentCard = ({
  handleCheckIn,
  handleArrived,
  handleShop,
  handleNoShow,
  handleResend,
  row,
}) => {
  const { status, patient = {}, doctor, notes: apptNotes = '', id, confirmed, prebooked } = row;
  const { email: patientEmail = '', phoneNumber = 'No Number', firstName = '', lastName = '' } = patient || {};

  const dispatch = useDispatch();
  const features = useAppSelector(state => state.auth?.me?.features);

  const modalRef = useRef<ModalContainerRef>(null);
  const notesFieldRef = useRef<HTMLTextAreaElement>(null);

  const [notes, setNotes] = useState<string>(apptNotes || '');
  const [areNotesEditable, setAreNotesEditable] = useState(false);

  const doctorName = formatDoctorName(doctor);

  const statusLabel = useMemo(() => {
    if (prebooked && features.includes(CONFIRM_EYE_EXAM_BOOKING_FEATURE_FLAG)) {
      return 'Prebooked';
    }

    if (status === APPOINTMENT.STATUS.CHECKED_IN) {
      return 'Checked-in';
    }

    return splitAndCapitalize(status);
  }, [status, prebooked]);

  const hasCheckedInOrCompleted = row.status && (
    [
      APPOINTMENT.STATUS.COMPLETE,
      APPOINTMENT.STATUS.CHECKED_IN,
    ].includes(row.status)
  );

  const hasCheckedInOnlineOrBooked = row.status && (
    [
      APPOINTMENT.STATUS.BOOKED,
      APPOINTMENT.STATUS.CHECKED_IN_ONLINE,
    ].includes(row.status)
  );

  const isDateValid = useMemo(() => (
    moment(row.endAt).diff(row.startAt, 'minutes') >= 5
  ), [row.status]);

  const onShop = useCallback(() => {
    handleShop?.(patientEmail ?? '', firstName ?? '', lastName ?? '', phoneNumber ?? 'No Number');
  }, [handleShop]);

  const onIntakeFormRemind = useCallback(() => {
    modalRef.current?.openModal();
  }, []);

  const onResend = useCallback(() => {
    handleResend?.(id ?? '');
  }, [handleResend]);

  const onCheckIn = useCallback(() => {
    handleCheckIn?.(row);
  }, [handleCheckIn]);

  const onArrived = useCallback(() => {
    handleArrived?.(row);
  }, [handleArrived]);

  const toggleNotesEditable = useCallback(() => {
    setAreNotesEditable(oldState => !oldState);
  }, []);

  const onCancelNotesEdit = useCallback(() => {
    setNotes(apptNotes);
    toggleNotesEditable();
  }, [toggleNotesEditable]);

  const onConfirmNotesEdit = useCallback(() => {
    toggleNotesEditable();

    dispatch(updateAppointmentData(row, { notes: notes || null }));
  }, [notes, toggleNotesEditable]);

  const onEditNotes = useCallback(e => {
    if (!e || !e.target) return;

    setNotes(e.target.value);
  }, []);

  useLayoutEffect(() => {
    notesFieldRef.current?.focus();
    notesFieldRef.current?.setSelectionRange(apptNotes?.length || 0, apptNotes?.length || 0);
  }, [areNotesEditable]);

  const getButtonLabel = () => {
    if (hasCheckedInOnlineOrBooked) {
      return 'Arrived';
    } if (hasCheckedInOrCompleted) {
      return 'Checked-in';
    }
    return 'Ready for Doctor';
  };

  return (
    // Avoid closing the container for click events inside it
    <Styled.Container onClick={e => e.stopPropagation()}>
      <RemindIntakeFormModal
        id={`intake-form-${id ?? 'unknown'}`}
        onRemindIntakeForm={onResend}
        ref={modalRef}
      />

      <Styled.DetailsContainer>
        <Styled.CustomContainer>
          <Styled.ParagraphContainer>
            <Styled.LabelBold>Doctor:</Styled.LabelBold>
            <Styled.Label>{doctorName}</Styled.Label>
          </Styled.ParagraphContainer>
          <Styled.ParagraphContainer>
            <Styled.LabelBold>Status:</Styled.LabelBold>
            <Styled.Label>{statusLabel}</Styled.Label>
          </Styled.ParagraphContainer>
          <Styled.ParagraphContainer>
            <Styled.LabelBold>Phone:</Styled.LabelBold>
            <Styled.Label>{phoneNumber}</Styled.Label>
          </Styled.ParagraphContainer>
          <FeatureFlagged feature={CONFIRM_EYE_EXAM_BOOKING_FEATURE_FLAG}>
            <Styled.ParagraphContainer>
              <Styled.LabelBold>Confirmed:</Styled.LabelBold>
              <ConfirmedTag confirmed={confirmed} />
            </Styled.ParagraphContainer>
          </FeatureFlagged>
        </Styled.CustomContainer>
        <Styled.ParagraphContainer>
          <Styled.LabelBold>Notes:</Styled.LabelBold>

          {areNotesEditable ? (
            <Styled.NotesFieldContainer>
              <Styled.NotesField
                ref={notesFieldRef}
                onChange={onEditNotes}
                rows={3}
                value={notes || ''}
              />
            </Styled.NotesFieldContainer>
          ) : (
            <Styled.Label>{notes || 'No Notes.'}</Styled.Label>
          )}
        </Styled.ParagraphContainer>
      </Styled.DetailsContainer>

      <Styled.Buttons>
        <FeatureFlagged feature={EYE_EXAM_TOOLING_INTAKE_FORM}>
          <Styled.CustomButton size="small" onClick={onIntakeFormRemind}>
            Resend Intake form
          </Styled.CustomButton>
        </FeatureFlagged>

        {areNotesEditable && (
          <>
            <Styled.IconButton
              type="cancel"
              onClick={onCancelNotesEdit}
            />

            <Styled.IconButton
              type="confirm"
              onClick={onConfirmNotesEdit}
            />
          </>
        )}

        {!areNotesEditable && (
          <>
            <Styled.CustomButton size="small" onClick={toggleNotesEditable}>
              Edit notes
            </Styled.CustomButton>

            <Styled.CustomButton
              size="small"
              disabled={hasCheckedInOrCompleted || !isDateValid}
              onClick={hasCheckedInOnlineOrBooked ? onArrived : onCheckIn}
            >
              {getButtonLabel()}
            </Styled.CustomButton>

            <Styled.CustomButton size="small" onClick={onShop}>
              Shop
            </Styled.CustomButton>

            <AppointmentOverflowMenu
              disabled={!isDateValid}
              handleNoShow={handleNoShow}
              appointment={row}
            />
          </>
        )}
      </Styled.Buttons>
    </Styled.Container>
  );
};

export default AppointmentCard;
