import React from 'react';
import { FilterCategory } from '../filter-category';
import { MenuContainer } from '../../appointments-sort-menu/styles';
import TimeRadioGroup from '../time-radio-group';
import { FilterType, TimeFilterOption } from '../../../../constants';

const ConfirmationStatusFilterOptions = ['Yes', 'No'];
const TypeFilterOptions = ['Adult', 'Kids', 'Glasses Only', 'In Person', 'Video Assited'];

interface FilterModalProps {
  selectedOptions: { [s: string]: string[] };
  disabledOptions: { [s: string]: string[] };
  filtersSectionRef: React.RefObject<HTMLDivElement>;
  handleSelectedOptions: (title: string, options: string[]) => void;
}

const FilterModal = ({
  selectedOptions,
  disabledOptions,
  filtersSectionRef,
  handleSelectedOptions,
}: FilterModalProps) => (
  <MenuContainer ref={filtersSectionRef}>
    <TimeRadioGroup
      disabledOptions={disabledOptions?.Time || []}
      options={[TimeFilterOption.Upcoming, TimeFilterOption.Past]}
      selectedOptions={selectedOptions?.Time || TimeFilterOption.Upcoming}
      onOptionChange={(option) => handleSelectedOptions(FilterType.Time, [option])}
    />
    <FilterCategory
      title="Confirmation Status"
      options={ConfirmationStatusFilterOptions}
      selectedOptions={selectedOptions['Confirmation Status'] || []}
      handleSelectedOptions={handleSelectedOptions}
      allowMultiple={false}
    />
    <FilterCategory
      title="Type"
      options={TypeFilterOptions}
      allowMultiple
        // eslint-disable-next-line @typescript-eslint/dot-notation
      selectedOptions={selectedOptions['Type'] || []}
      handleSelectedOptions={handleSelectedOptions}
    />
  </MenuContainer>
);

export default FilterModal;
