import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../hooks';
import { selectIsModalOpen, selectModalCustomProps, setIsModalOpen } from '../middleware/modals/actions';
import { ModalType } from '../middleware/modals/types';
import ConfirmAppointmentModal from './appointments/confirm-appointment-modal/confirm-appointment-modal';

const AppModals = () => {
  const dispatch = useDispatch();

  const isConfirmAppointmentModalOpen = useAppSelector((state) =>
    selectIsModalOpen(state, ModalType.ConfirmAppointment),
  );

  const confirmModalProps = useAppSelector((state) =>
    selectModalCustomProps(state, ModalType.ConfirmAppointment),
  );

  return (
    <>
      <ConfirmAppointmentModal
        isOpen={isConfirmAppointmentModalOpen}
        onClose={() =>
          dispatch(
            setIsModalOpen(
              {
                type: ModalType.ConfirmAppointment,
                isOpen: false,
              },
            ),
          )}
        appointmentId={confirmModalProps?.appointmentId}
      />
    </>
  );
};

export default AppModals;
