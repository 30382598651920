import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { getCurrentDateInTimeZone } from '../utils/formatDate';
import { useAppSelector } from '../hooks';
import { updateAppointmentsFilter } from '../middleware/eye-exams/actions';
import { FilterType, TimeFilterOption } from '../constants';

interface UseDateToolbarStateReturnValue {
  currentDate: DateTime;
  onDateChanged: (newDate: DateTime) => void;
  onToday: () => void;
  onNextDay: () => void;
  onPreviousDay: () => void;
}

export default function useDateToolbarState(): UseDateToolbarStateReturnValue {
  const dispatch = useDispatch();
  const facilityTimeZone = useAppSelector((state) => state.auth.me.facility.timezone);
  const filterItem = useAppSelector((state) => state.eyeExams.appointments.filterItem);

  const [dateTime, setDateTime] = useState<DateTime>(getCurrentDateInTimeZone(facilityTimeZone));
  const currentDateInTimeZone = getCurrentDateInTimeZone(facilityTimeZone);

  useEffect(() => {
    const timeOption = dateTime < currentDateInTimeZone
      ? TimeFilterOption.Past
      : TimeFilterOption.Upcoming;

    dispatch(updateAppointmentsFilter({
      ...filterItem,
      [FilterType.Time]: [timeOption],
    }));
  }, [dateTime]);

  const onDateChanged = (newDate: DateTime) => {
    setDateTime(newDate);
  };

  const onToday = () => {
    setDateTime(getCurrentDateInTimeZone(facilityTimeZone));
  };

  const onPreviousDay = () => {
    setDateTime(dateTime.minus({ days: 1 }));
  };

  const onNextDay = () => {
    setDateTime(dateTime.plus({ days: 1 }));
  };

  return { currentDate: dateTime, onDateChanged, onToday, onNextDay, onPreviousDay };
}
