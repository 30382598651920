import styled from '@emotion/styled';
import { colors } from '../../../../styles/colors';

export const RadioButtonGroup = styled.fieldset`
  border: none;
  padding: 0;
  column-gap: 20px;
  display: flex;
`;

export const RadioButtonLabel = styled.label<{ disabled: boolean }>`
  display: flex;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
  color: ${colors.charcoal};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const RadioButton = styled.input`
  width: 24px;
  height: 24px;
  margin-right: 10px;

  &:checked {
    accent-color: ${colors.allports};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;
