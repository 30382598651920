import styled from '@emotion/styled';
import { colors } from '../../styles/colors';

export const Container = styled('div')`
  display: flex;
  width: 768px;
  margin: 0 auto;
  align-items: stretch;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
`;

export const EmptyListMessage = styled('p')`
  color: ${colors.grayChateau};
  font-size: 20px;
  margin: 218px auto 0;
  max-width: 768px;
  text-align: center;
`;
