import { FilterStrategy, FilterType } from 'src/constants';
import { FilterItems } from 'src/middleware/eye-exams/types';

const typeMapping: { [key: string]: string } = {
  Kids: 'MINOR',
};

function mapStringArray(inputArray: string[]): string[] {
  return inputArray.map((s) => {
    const transformedString = typeMapping[s] || s;
    return transformedString.replace(/ /g, '_').toUpperCase();
  });
}

export const mapFilterToConfigurations = (filterItem: FilterItems): {
  [key in FilterStrategy]: string[] } => {
  const configurations: { [key in FilterStrategy]: string[] } = {
    [FilterStrategy.Include]: [],
    [FilterStrategy.Exclude]: [],
    [FilterStrategy.Equal]: [],
  };

  if (filterItem.Type) {
    const transformedTypes = mapStringArray(filterItem.Type);
    configurations[FilterStrategy.Include].push(...transformedTypes);
  }

  if (filterItem[FilterType.ConfirmationStatus]
    && filterItem[FilterType.ConfirmationStatus].length > 0) {
    configurations[FilterStrategy.Equal].push(filterItem[FilterType.ConfirmationStatus][0]);
  }

  return configurations;
};
