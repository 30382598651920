import React from 'react';
import { SortItem } from 'src/middleware/eye-exams/types';
import { SortOptions } from '../sort-options';
import { MenuContainer } from '../styles';

interface SortModalProps {
  setExpandedSortMenu: (value: boolean) => void;
  filtersSectionRef: React.RefObject<HTMLDivElement>;
  onSortChange: (item: SortItem) => void;
  sortItem: SortItem;
}

const SortModal: React.FC<SortModalProps> = ({
  sortItem,
  setExpandedSortMenu,
  filtersSectionRef,
  onSortChange,
}) => (
  <MenuContainer ref={filtersSectionRef}>
    <SortOptions
      onSortChange={onSortChange}
      sortItem={sortItem}
      setExpandedSortMenu={setExpandedSortMenu}
    />
  </MenuContainer>
);

export default SortModal;
