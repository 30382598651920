import React from 'react';
import ReactDOM from 'react-dom';
import Modal from './index';

export interface StyledModalProps {
  isOpen: boolean;
  onClose?: () => void;
}

const StyledModal = ({ isOpen, children }: StyledModalProps & { children: React.ReactNode }) => {
  if (!isOpen) {
    return null;
  }

  const modalRoot = document.getElementById('modal-root');
  if (!modalRoot) {
    return null;
  }

  return ReactDOM.createPortal(
    <Modal hasCloseButton={false}>{children}</Modal>, modalRoot,
  );
};

export default StyledModal;
