export const handleSelection = (
  selected: string[],
  option: string,
  allowMultiple: boolean,
): string[] => {
  if (selected.includes(option)) {
    return selected.filter((item) => item !== option);
  }
  return allowMultiple ? [...selected, option] : [option];
};
