import { ModalType, SetIsModalOpenPayload } from './types';

export const SET_IS_MODAL_OPEN = 'SET_IS_MODAL_OPEN';

export const setIsModalOpen = (payload: SetIsModalOpenPayload) => ({
  type: SET_IS_MODAL_OPEN,
  payload,
});

export const selectIsModalOpen = (state, type: ModalType) =>
  state.modals[type].isOpen;

export const selectModalCustomProps = (state, type: ModalType) =>
  state.modals[type].customProps;

export const selectConfirmModalOpen = (state) =>
  selectIsModalOpen(state, ModalType.ConfirmAppointment);
