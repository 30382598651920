import styled from '@emotion/styled';
import { colors } from '../../../styles/colors';

export const PaddingSetter = styled('div')<{ selected: boolean }>`
  cursor: pointer;
  background: ${colors.white};
  box-shadow: 0 1px 4px ${colors.blackShadow4pct}, 0 4px 12px ${colors.blackShadow2pct};
  padding: 24px;
  border: ${(props) => `1px solid ${props.selected ? colors.charcoal : 'transparent'}`};
  border-radius: 8px;
  box-sizing: border-box;
`;

export const PaddingSetterPaginate = styled('div')`
  width: 100%;
  background: transparent;
  color: ${colors.grayChateau};
  font-size: 20px;
  max-width: 700px;
  max-height: 400px;
  text-align: center;
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  font-family: proxima-nova, sans-serif;
`;

export const FiltersContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
`;

export const SummaryColumn = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  gap: 16px;
`;

export const DetailColumn = styled('span')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 4px;
  align-self: stretch;
  flex-grow: 1;
`;

export const IconColumn = styled('span')`
  display: flex;
  align-items: center;
`;

export const TimeColumn = styled('span')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.grayChateau};
`;

export const VisitorName = styled('div')`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.charcoal};
`;

export const AppointmentType = styled('div')`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${colors.grayChateau};
`;

export const CategoriesIcon = styled.span<{ icon?: string }>`
  background: ${(props) =>
    (props.icon ? `url(${props.icon}) no-repeat center` : 'none')};
  background-size: cover;
  width: 48px;
  height: 48px;
`;

export const EmptyListMessage = styled('p')`
  color: ${colors.grayChateau};
  font-size: 20px;
  max-width: 768px;
  text-align: center;
`;

export const AppointmentList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 32px 24px;
`;
