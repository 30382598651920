import { DateTime } from 'luxon';
import { DATE_FORMAT, TimeFilterType } from 'src/constants';

export const formatDate = (date: Date | number): string =>
  Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
    .format(date)
    .split('/')
    .reverse()
    .join('/');

export const findNextEarliestDate = (datesObject) => {
  const filteredDates: string[] = [];

  Object.keys(datesObject).forEach((dateStr) => {
    if (datesObject[dateStr]) {
      filteredDates.push(dateStr);
    }
  });
  const currentDate = new Date();
  const today = currentDate.toISOString().split('T')[0];
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  const formattedTomorrow = tomorrow.toISOString().split('T')[0];

  // since the data from API is comming sorted
  // we can get the first value as the earliest date
  const earliestDate = filteredDates[0];
  if (earliestDate) {
    if (earliestDate === today) {
      return 'Today';
    }
    if (earliestDate === formattedTomorrow) {
      return 'Tomorrow';
    }
    return earliestDate;
  }
  return 'No upcoming appointments';
};

export const formatDateToDayAndMonth = (date: Date | DateTime): string => {
  const dateTime = date instanceof Date ? DateTime.fromJSDate(date) : date;
  return dateTime.setLocale('en-US').toLocaleString({
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  });
};

export const getCurrentDateInTimeZone = (timezone: string): DateTime =>
  DateTime.now().setZone(timezone).startOf('day');

export const getDateRange = (
  dateTime: DateTime,
  timeFilter: string,
  timeZone: string,
): { fromDate: DateTime; toDate: DateTime } => {
  const dateInZone = dateTime.setZone(timeZone);
  const nowInZone = DateTime.now().setZone(timeZone);

  const dayStart = dateInZone.startOf('day');
  const dayEnd = dateInZone.endOf('day');

  if (!dateInZone.hasSame(nowInZone, 'day')) {
    return { fromDate: dayStart, toDate: dayEnd };
  }

  if (timeFilter === TimeFilterType.Upcoming) {
    return { fromDate: nowInZone, toDate: dayEnd };
  }

  if (timeFilter === TimeFilterType.Past) {
    return { fromDate: dayStart, toDate: nowInZone };
  }

  return { fromDate: dayStart, toDate: dayEnd };
};

export const formatToQueryString = (date: DateTime, timeZone: string): string =>
  date.setZone(timeZone).toFormat(DATE_FORMAT);
