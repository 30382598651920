import React from 'react';
import { FilterOption } from '../filter-option';
import { handleSelection } from './selectionUtils';
import { FilterCategoryContainer, FilterCategoryTitle, OptionsContainer } from './styles';

interface FilterCategoryProps {
  title: string;
  options: string[];
  allowMultiple: boolean;
  selectedOptions: string[];
  handleSelectedOptions: (title: string, options: string[]) => void;
}

export const FilterCategory: React.FC<FilterCategoryProps> = ({
  title,
  options,
  allowMultiple,
  selectedOptions,
  handleSelectedOptions,
}) => {
  const handleOptionClick = (option: string) => {
    const newSelectedOptions = handleSelection(
      selectedOptions,
      option,
      allowMultiple,
    );
    handleSelectedOptions(title, newSelectedOptions);
  };

  return (
    <FilterCategoryContainer>
      <FilterCategoryTitle>{title}</FilterCategoryTitle>
      <OptionsContainer>
        {options.map((option) => (
          <FilterOption
            key={option}
            option={option}
            onClick={handleOptionClick}
            isSelected={selectedOptions.includes(option)}
          />
        ),
        )}
      </OptionsContainer>
    </FilterCategoryContainer>
  );
};
