import { applyMiddleware, combineReducers, createStore, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import auth from './middleware/auth';
import eyeExams from './middleware/eye-exams';
import waitlist from './middleware/waitlist';
import customerSearch from './middleware/customer-search';
import frameStyleAssistant from './middleware/frame-style-assistant';
import frameConfig from './middleware/frame-config';
import favorites from './middleware/favorites';
import modals from './middleware/modals';

const rootReducer = combineReducers({
  auth: auth.reducer,
  eyeExams: eyeExams.reducer,
  waitlist: waitlist.reducer,
  customerSearch: customerSearch.reducer,
  frameStyleAssistant: frameStyleAssistant.reducer,
  frameConfig: frameConfig.reducer,
  favorites: favorites.reducer,
  modals: modals.reducer,
});

export const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({ trace: true });

const iosShimMiddleware: Middleware = (store) =>
  (next) =>
    (action) => {
      if (action.type === 'QR_CODE_REQUEST_SCAN') {
        setTimeout(
          () =>
            store.dispatch({
              type: 'QR_PICKER_DID_SCAN',
              payload: {
                // add test data for qr scan here
                data: {
                  /**
                   * ios, android, web, mweb, unknown
                   */
                  reference: 'unknown',
                  // Make sure to revert back to the empty string when you're done
                  // Do not commit your test id :)
                  id: '',
                },
              },
            }),
          1000,
        );
      }

      return next(action);
    };

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware, window.iosMiddleware || iosShimMiddleware),
  ),
);

window.REDUX_STORE = store;

export type RootState = ReturnType<typeof store.getState>;
