import styled from '@emotion/styled';
import { colors } from '../../styles/colors';

export interface ConfirmedTagProps {
  confirmed: boolean;
}

export const TagContainer = styled.div<ConfirmedTagProps>`
  display: flex;
  padding: 4px 8px 2px;
  border-radius: 4px;
  background-color: ${props => (props.confirmed ? colors.lightGreen : colors.lightRed)};
`;

export const TagText = styled.span<ConfirmedTagProps>`
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  font-style: normal;
  color: ${props => (props.confirmed ? colors.green : colors.red)};
`;
