import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Buttons,
  CancelButton,
  CheckIcon,
  ConfirmButton,
  Container,
  Content,
  ErrorContainer,
  InfoText,
  TextContainer,
  Title,
} from './styles';
import StyledModal, { StyledModalProps } from '../../../modal/styled-modal';
import { partialUpdateAppointmentData } from '../../../middleware/eye-exams/actions';
import { useAppSelector } from '../../../hooks';

export interface ConfirmAppointmentModalProps extends StyledModalProps{
  appointmentId?: string | null;
}

const ConfirmAppointmentModal = ({
  isOpen,
  appointmentId,
  onClose,
}: ConfirmAppointmentModalProps) => {
  const dispatch = useDispatch();

  const appointmentUpdateLoading = useAppSelector((state) =>
    state.eyeExams.loading.isAppointmentUpdating);
  const appointmentUpdateError = useAppSelector((state) =>
    state.eyeExams.errors.appointmentUpdateMessage);

  const handleConfirmClick = () => {
    if (appointmentId) {
      dispatch(partialUpdateAppointmentData(appointmentId, { confirmed: true }));
    }
  };

  return (
    <StyledModal isOpen={isOpen}>
      <Container>
        <Content>
          <CheckIcon />
          <TextContainer>
            <Title>Confirm Appointment?</Title>
            <InfoText>
              {`Are you sure you want to confirm this appointment?
            This action will send an email to the patient confirming
            the time, date, and location of this appointment.`}
            </InfoText>
            {appointmentUpdateError && <ErrorContainer>{appointmentUpdateError}</ErrorContainer>}
          </TextContainer>
        </Content>
        <Buttons>
          <CancelButton color="secondary" onClick={() => onClose?.()} fullWidth>
            No
          </CancelButton>
          <ConfirmButton color="primary" onClick={handleConfirmClick} loading={appointmentUpdateLoading} fullWidth>
            Yes
          </ConfirmButton>
        </Buttons>
      </Container>
    </StyledModal>
  );
};

export default ConfirmAppointmentModal;
