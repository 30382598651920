import styled from '@emotion/styled';
import { colors } from 'src/styles/colors';
import FiltersBubble from '../active-filters-bubble.svg';

export const Container = styled.div`
  top: 184px;
  z-index: 1;
  padding: 24px 32px;
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 24px;
  background-color: ${colors.silk};
  font-family: proxima-nova, sans-serif;
`;

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TodayButton = styled.button`
  background-color: transparent;
  border: 1px solid ${colors.silver};
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 100px;
  padding: 6px 16px;
  line-height: 20px;
  cursor: pointer;
  color: ${colors.charcoal};
`;

export const DateSection = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DateDisplay = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.charcoal};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const IconGroup = styled.div`
  display: inline-flex;
  border: 1px solid ${colors.silver};
  border-radius: 9999px;
  overflow: hidden;
  padding: 8px 12px;
  width: 50px;
  height: 14px;
  align-items: center;
  justify-content: center;
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.charcoal};
  height: 16px;

  &:first-of-type {
    border-right: 1px solid ${colors.silver};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const RoundIconButton = styled.button`
  background: none;
  border: 1px solid ${colors.silver};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.charcoal};
  width: 24px;
  height: 24px;
  padding: 0;
  overflow: hidden;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
`;

export const ButtonContainer = styled.div`
  flex: 1;
  gap: 12px;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const Button = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  background-color: ${(props) => (props.isActive ? colors.dustyBlue : 'transparent')};
  border: 1px solid ${(props) => (props.isActive ? 'transparent' : colors.silver)};
  border-radius: 20px;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  color: ${(props) => (props.isActive ? colors.allports : colors.charcoal)};

  svg {
    color: ${(props) => (props.isActive ? colors.allports : colors.charcoal)};
  }
`;

export const ActiveFiltersBubble = styled('div')`
  background: url(${FiltersBubble}) no-repeat;
  width: 16px;
  height: 16px;
  font-weight: 600;
  font-size: 12px;
  color: ${colors.white};
  text-align: center;
  line-height: 15px;
`;
