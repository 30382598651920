import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ArrowLeft16Icon,
  ArrowRight16Icon,
  CaretDown12Icon,
  FilterIcon,
  MenuIcon,
} from '@warbyparker/retail-design-system';
import { FilterItems, SortItem } from 'src/middleware/eye-exams/types';
import { useDispatch } from 'react-redux';
import { updateAppointmentsCurrentDate, updateAppointmentsFilter } from 'src/middleware/eye-exams/actions';
import {
  ActiveFiltersBubble,
  Button,
  ButtonContainer,
  ButtonGroup,
  Container,
  DateDisplay,
  IconButton,
  IconGroup,
  RoundIconButton,
  TodayButton,
  TopRow,
} from './styles';
import DatePicker from './components/date-picker';
import { formatDateToDayAndMonth, getCurrentDateInTimeZone } from '../../utils/formatDate';
import useDateToolbarState from '../../hooks/useDateToolbarState';
import FilterModal from '../appointments/appointmets-filter/filter-modal';
import { activeFiltersCount } from '../../utils/customerList';
import SortModal from '../appointments/appointments-sort-menu/sort-modal';
import { useAppSelector } from '../../hooks';
import { FilterType, TimeFilterOption } from '../../constants';

interface DataViewToolbarProps {
  onSortClick?: () => void;
  onSortChange: (item: SortItem) => void;
  filterItem: FilterItems;
  sortItem: SortItem;
}

const DataViewToolbar: React.FC<DataViewToolbarProps> = ({
  onSortClick = () => { },
  onSortChange,
  filterItem,
  sortItem,
}) => {
  const dispatch = useDispatch();
  const facilityTimeZone = useAppSelector((state) => state.auth.me.facility.timezone);

  const [expandedFilters, setExpandedFilters] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<FilterItems>({});
  const [disabledOptions, setDisabledOptions] = useState<FilterItems>({});
  const [expandedSortMenu, setExpandedSortMenu] = useState<boolean>(false);
  const [datePickerIsOpened, setDatePickerIsOpened] = useState<boolean>(false);
  const {
    currentDate,
    onDateChanged,
    onToday,
    onPreviousDay,
    onNextDay,
  } = useDateToolbarState();

  const filtersSectionRef = useRef<HTMLDivElement>(null);
  const filtersButtonRef = useRef<HTMLButtonElement>(null);
  const sortButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setDatePickerIsOpened(false);

    if (currentDate) {
      dispatch(updateAppointmentsCurrentDate(currentDate));
    }
  }, [currentDate]);

  useEffect(() => {
    setSelectedOptions(filterItem);
  }, [filterItem]);

  const handleOutsideClick = (event) => {
    const isClickOutsideFilters = filtersSectionRef.current
      && !filtersSectionRef.current.contains(event.target);
    const isClickOutsideFiltersButton = filtersButtonRef.current
      && !filtersButtonRef.current.contains(event.target);
    const isClickOutsideSortButton = sortButtonRef.current
      && !sortButtonRef.current.contains(event.target);

    if (isClickOutsideFilters && isClickOutsideFiltersButton) {
      setExpandedFilters(false);
    }
    if (isClickOutsideFilters && isClickOutsideSortButton) {
      setExpandedSortMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!expandedFilters) {
      dispatch(updateAppointmentsFilter(selectedOptions));
    }
  }, [expandedFilters]);

  useEffect(() => {
    let disabledOption: string[] = [];
    const currentDateInTimeZone = getCurrentDateInTimeZone(facilityTimeZone);

    if (currentDate > currentDateInTimeZone) {
      disabledOption = [TimeFilterOption.Past];
    } else if (currentDate < currentDateInTimeZone) {
      disabledOption = [TimeFilterOption.Upcoming];
    }

    setDisabledOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [FilterType.Time]: disabledOption,
    }));
  }, [currentDate, facilityTimeZone]);

  const activeFiltersNumber = useMemo(() =>
    activeFiltersCount(selectedOptions, [TimeFilterOption.Upcoming]),
  [selectedOptions, currentDate],
  );

  const handleFilterButtonClick = () => {
    setExpandedFilters((prevSelected) => !prevSelected);
  };

  const handleSelectedOptions = (title: string, options: string[]) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [title]: options,
    }));
  };

  const handleSortButtonClick = () => {
    setExpandedSortMenu((prevSelected) => !prevSelected);
    onSortClick?.();
  };

  return (
    <Container>
      <TopRow>
        <TodayButton onClick={onToday}>Today</TodayButton>
        <DatePicker
          currentDate={currentDate}
          onDateChanged={onDateChanged}
          isOpened={datePickerIsOpened}
          facilityTimeZone={facilityTimeZone}
          setOpened={() => setDatePickerIsOpened(!datePickerIsOpened)}
        >
          <DateDisplay onClick={() => setDatePickerIsOpened(!datePickerIsOpened)}>
            {formatDateToDayAndMonth(currentDate)}
            <RoundIconButton>
              <CaretDown12Icon />
            </RoundIconButton>
          </DateDisplay>
        </DatePicker>
        <IconGroup>
          <IconButton onClick={onPreviousDay}>
            <ArrowLeft16Icon />
          </IconButton>
          <IconButton
            data-testid="data-view-toolbar-next-day-button"
            onClick={onNextDay}
          >
            <ArrowRight16Icon />
          </IconButton>
        </IconGroup>
      </TopRow>
      <ButtonGroup>
        <ButtonContainer>
          <Button
            onClick={handleFilterButtonClick}
            isActive={expandedFilters}
            ref={filtersButtonRef}
          >
            <FilterIcon />
            Filter
            {activeFiltersNumber > 0
              && (
                <ActiveFiltersBubble>
                  {activeFiltersNumber}
                </ActiveFiltersBubble>
              )}
          </Button>
          {
            expandedFilters && (
              <FilterModal
                disabledOptions={disabledOptions}
                selectedOptions={selectedOptions}
                handleSelectedOptions={handleSelectedOptions}
                filtersSectionRef={filtersSectionRef}
              />
            )
          }
        </ButtonContainer>
        <ButtonContainer>
          <Button
            onClick={handleSortButtonClick}
            isActive={expandedSortMenu}
            ref={sortButtonRef}
          >
            <MenuIcon />
            Sort
          </Button>
          {
            expandedSortMenu && (
              <SortModal
                filtersSectionRef={filtersSectionRef}
                onSortChange={onSortChange}
                sortItem={sortItem}
                setExpandedSortMenu={setExpandedSortMenu}
              />
            )
          }
        </ButtonContainer>
      </ButtonGroup>
    </Container>
  );
};

export default DataViewToolbar;
