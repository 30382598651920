import { DateTime } from 'luxon';
import type { Appointment } from '../../service-clients/pmp-api-types';
import { FilterItems, SortItem } from './types';

const REQUEST_POE_NATIVE_EYE_EXAM = 'REQUEST_POE_NATIVE_EYE_EXAM';

export const FETCH_APPOINTMENTS = 'FETCH_APPOINTMENTS';
export const FETCH_FACILITY_EXAM_SERVICES = 'FETCH_FACILITY_EXAM_SERVICES';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const PARTIAL_UPDATE_APPOINTMENT = 'PARTIAL_UPDATE_APPOINTMENT';

export const FETCH_APPOINTMENTS_SUCCESS = 'FETCH_APPOINTMENTS_SUCCESS';
export const FETCH_APPOINTMENTS_FAILURE = 'FETCH_APPOINTMENTS_FAILURE';

export const FETCH_FACILITY_EXAM_SERVICES_SUCCESS = 'FETCH_FACILITY_EXAM_SERVICES_SUCCESS';
export const FETCH_FACILITY_EXAM_SERVICES_FAILURE = 'FETCH_FACILITY_EXAM_SERVICES_FAILURE';

export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAILURE = 'UPDATE_APPOINTMENT_FAILURE';

export const PARTIAL_UPDATE_APPOINTMENT_SUCCESS = 'PARTIAL_UPDATE_APPOINTMENT_SUCCESS';
export const PARTIAL_UPDATE_APPOINTMENT_FAILURE = 'PARTIAL_UPDATE_APPOINTMENT_FAILURE';

export const REQUEST_APPOINTMENT_MODIFICATION = 'REQUEST_APPOINTMENT_MODIFICATION';

export const UPDATE_APPOINTMENTS_SORT = 'UPDATE_APPOINTMENTS_SORT';
export const RESET_APPOINTMENTS_SORT = 'RESET_APPOINTMENTS_SORT';

export const UPDATE_APPOINTMENTS_FILTER = 'UPDATE_APPOINTMENTS_FILTER';

export const UPDATE_APPOINTMENTS_DATE = 'UPDATE_APPOINTMENTS_DATE';

export const requestNativeEyeExamBooking = () => ({
  type: REQUEST_POE_NATIVE_EYE_EXAM,
});

export const updateAppointmentsSort = (payload: SortItem) => ({
  type: UPDATE_APPOINTMENTS_SORT,
  payload,
});

export const updateAppointmentsCurrentDate = (payload: DateTime) => ({
  type: UPDATE_APPOINTMENTS_DATE,
  payload,
});

export const requestAppointmentModificationAction = (
  payload: { modifyAppointmentUrl: string },
) => ({
  type: REQUEST_APPOINTMENT_MODIFICATION,
  payload,
});

export const partialUpdateAppointmentData = (
  appointmentId: string,
  appointmentData: Partial<Appointment>,
) => ({
  type: PARTIAL_UPDATE_APPOINTMENT,
  payload: { appointmentId, appointmentData },
});

export const partialUpdateAppointmentSuccess = (
  appointmentId: string,
  appointmentData: Partial<Appointment>,
) =>
  ({
    type: PARTIAL_UPDATE_APPOINTMENT_SUCCESS,
    payload: { appointmentId, appointmentData },
  });

export const partialUpdateAppointmentFailure = (error: string) => ({
  type: PARTIAL_UPDATE_APPOINTMENT_FAILURE,
  payload: error,
});

export const resetAppointmentsSort = () => ({
  type: RESET_APPOINTMENTS_SORT,
});

export const updateAppointmentsFilter = (payload: FilterItems) => ({
  type: UPDATE_APPOINTMENTS_FILTER,
  payload,
});
