import React, { useCallback, useMemo } from 'react';
import { Popover } from '@mantine/core';
import { DateTime } from 'luxon';
import { StyledDatePicker } from './styles';
import { DateSection } from '../../styles';

interface DatePickerProps {
  currentDate: DateTime;
  facilityTimeZone: string;
  onDateChanged: (date: DateTime) => void;
  isOpened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const DatePicker: React.FC<DatePickerProps> = ({
  children,
  currentDate,
  facilityTimeZone,
  onDateChanged,
  isOpened,
  setOpened,
}) => {
  const dateForPicker = useMemo(() => new Date(currentDate.toFormat('yyyy-MM-dd')), [currentDate]);

  const formatWeekday = useCallback(
    (date: Date) => date.toLocaleString('en-US', { weekday: 'narrow' }),
    [],
  );

  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (date) {
        onDateChanged(DateTime.fromJSDate(date)
          .setZone(facilityTimeZone, { keepLocalTime: true })
          .startOf('day'));
      }
    },
    [facilityTimeZone],
  );

  return (
    <Popover
      position="bottom"
      shadow="md"
      radius={12}
      opened={isOpened}
      onChange={setOpened}
    >
      <DateSection>
        <Popover.Target>{children}</Popover.Target>
        <Popover.Dropdown>
          <StyledDatePicker
            size="xs"
            hideOutsideDates
            firstDayOfWeek={0}
            value={dateForPicker}
            defaultDate={dateForPicker}
            onChange={handleDateChange}
            weekdayFormat={formatWeekday}
          />
        </Popover.Dropdown>
      </DateSection>
    </Popover>
  );
};

export default DatePicker;
