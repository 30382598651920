export interface ModalsState {
  [key: string]: ModalState;
}

export interface ModalState {
  isOpen: boolean;
  onClose?: () => void;
  customProps?: ModalCustomProps;
}

export interface ModalCustomProps {
  [key: string]: string | null;
}

export enum ModalType {
  ConfirmAppointment = 'confirm-appointment',
}

export interface SetIsModalOpenPayload {
  type: ModalType;
  isOpen: boolean;
  customProps?: ModalCustomProps;
}
