import React from 'react';
import styled from '@emotion/styled';
import { FrameItem } from './frame-item';
import { resolutions } from '../../styles/resolutions';

const FrameGalleryWrapper = styled.div`
  margin: 0 30px;
  column-gap: 22px;
  @media (max-width: ${resolutions.tabletMax}) {
    column-gap: 16px;
    margin: 0;
  }
  @media (max-width: ${resolutions.tabletSMax}) {
    column-gap: 10px;
    margin: 0;
  }
  padding-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const FrameGallery = ({ frames }) => (
  <FrameGalleryWrapper data-testid="frame-gallery-element">
    {
        frames && frames.filter(
          (frameFamily) => frameFamily.length > 0,
        ).map(
          (frameFamily) => <FrameItem key={frameFamily[0].id} frameFamily={frameFamily} />,
        )
      }
  </FrameGalleryWrapper>
);

export default FrameGallery;
