import React from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import { MantineProvider } from '@mantine/core';

import auth, { AUTH_REQUEST } from './middleware/auth';
import eyeExams from './middleware/eye-exams';
import waitlist from './middleware/waitlist';
import customerSearch from './middleware/customer-search';
import frameStyleAssistant from './middleware/frame-style-assistant';
import frameConfig from './middleware/frame-config';
import favorites from './middleware/favorites';

import LoadingPage from './components/loading-page';
import ErrorPage from './components/error-page';
import WaitlistPage, { AddAssigneeModalPage, RemoveCustomerModalPage } from './components/waitlist/waitlist-page';
import WaitlistAddCustomerPage from './components/add-customer-page';
import WaitlistEditCustomerPage from './components/edit-customer-page';
import WaitlistViewCustomerPage from './components/view-customer-page';
import { FrameConfigPage } from './components/frame-style-assistant/frame-config-page';
import FrameStyleAssistantPage from './components/frame-style-assistant';
import { sagaMiddleware, store } from './store';
import { ConnectedSnowplowProvider as SnowplowProvider } from './providers/snowplow';
import { ToastProvider } from './contexts/toast';
import AppModals from './components/app-modals';

const select = (state) => ({
  me: state.auth.me,
  jwt: state.auth.token,
  error: state.auth.error,
});

const AuthedApp = connect(select)(({ jwt, me, error, dispatch }) => {
  if (!jwt) {
    dispatch({ type: AUTH_REQUEST });
    return <LoadingPage />;
  }

  if (!me) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <ToastProvider>
      <Switch>
        <Route path="/add-customer">
          <WaitlistAddCustomerPage />
        </Route>
        <Route path="/edit-customer/:positionId">
          <WaitlistEditCustomerPage />
        </Route>
        <Route path="/view-customer/:positionId">
          <WaitlistViewCustomerPage />
        </Route>
        <Route path="/add-assignee/:positionId?">
          <AddAssigneeModalPage />
        </Route>
        <Route path="/remove-customer/:positionId?">
          <RemoveCustomerModalPage />
        </Route>
        <Route path="/frame-style-assistant">
          <FrameStyleAssistantPage />
        </Route>
        <Route path="/frame-config/:frameId">
          <FrameConfigPage />
        </Route>
        <Route path="/">
          <WaitlistPage />
        </Route>
      </Switch>
    </ToastProvider>
  );
});

const RoutedApp = () => {
  // By starting the Sagas after our Router is mounted,
  // we can pass the Router's history object to the Sagas.
  // This lets us redirect to different pages from within Sagas.
  const history = useHistory();
  sagaMiddleware.run(auth.saga);
  sagaMiddleware.run(customerSearch.saga);
  sagaMiddleware.run(eyeExams.saga);
  sagaMiddleware.run(waitlist.saga, history);
  sagaMiddleware.run(frameStyleAssistant.saga);
  sagaMiddleware.run(frameConfig.saga);
  sagaMiddleware.run(favorites.saga);
  return <AuthedApp />;
};

const App = () => (
  <MantineProvider withNormalizeCSS>
    <Provider store={store}>
      <SnowplowProvider>
        <Router>
          <RoutedApp />
          <AppModals />
        </Router>
      </SnowplowProvider>
    </Provider>
  </MantineProvider>
);

export default App;
