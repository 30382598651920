import React from 'react';
import { FilterCategoryContainer, FilterCategoryTitle } from '../filter-category/styles';
import { RadioButton, RadioButtonGroup, RadioButtonLabel } from './styles';

interface TimeRadioGroupProps {
  options: string[];
  selectedOptions: string[];
  disabledOptions: string[];
  onOptionChange: (option: string) => void;
}

const TimeRadioGroup: React.FC<TimeRadioGroupProps> = ({
  options,
  onOptionChange,
  selectedOptions,
  disabledOptions,
}) => (
  <FilterCategoryContainer>
    <FilterCategoryTitle>Time</FilterCategoryTitle>
    <RadioButtonGroup>
      {options.map((option) => {
        const disabled = disabledOptions.includes(option);
        return (
          <RadioButtonLabel key={`${option}-label`} disabled={disabled}>
            <RadioButton
              disabled={disabled}
              type="radio"
              value={option}
              checked={selectedOptions.includes(option)}
              onChange={() => onOptionChange(option)}
            />
            {option}
          </RadioButtonLabel>
        );
      })}
    </RadioButtonGroup>
  </FilterCategoryContainer>
);

export default TimeRadioGroup;
