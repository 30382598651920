const formatName = (firstName: string, lastName: string, abbreviateLastName = false) => {
  if (!firstName && !lastName) return null;

  if (!lastName) return firstName;

  return `${firstName} ${abbreviateLastName ? `${lastName.charAt(0)}.` : lastName}`;
};

// =============================================================================
// Exports
// =============================================================================

const formatDoctorName = doctor => {
  const { firstName = '', lastName = '' } = doctor || {};

  return formatName(firstName, lastName) ?? 'Unknown Doctor';
};

const formatPatientName = patient => {
  const { firstName = '', lastName = '' } = patient || {};

  return formatName(firstName, lastName, true) ?? 'Unknown Patient';
};

export { formatDoctorName, formatPatientName };
