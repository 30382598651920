import React, { useEffect, useMemo, useState } from 'react';
import { CONFIRM_EYE_EXAM_BOOKING_FEATURE_FLAG } from 'feature-flags';
import { connect, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { Spinner } from '../spinner';
import FeatureFlagged from '../feature-flagged';
import DataViewToolbar from '../data-view-toolbar/dataViewToolbar';
import AppointmentList from '../appointments/appointment-list';
import { Container, EmptyListMessage } from './styles';
import { filterAppointmentsByTime } from '../../utils/customerList';
import { AppointmentsState, FilterItems, SortItem } from '../../middleware/eye-exams/types';
import { resetAppointmentsSort, updateAppointmentsSort } from '../../middleware/eye-exams/actions';
import ErrorBlock from '../error-block';
import { fetchAppointmentsList } from '../../middleware/eye-exams';
import { INITIAL_PAGE, PAGE_SIZE } from '../../constants';

interface EyeExamsTabProps {
  error: string,
  sortItem: SortItem,
  filterItem: FilterItems,
  appointments: AppointmentsState,
  isAppointmentsLoading: boolean,
  confirmEyeExamFeatureEnabled: boolean,
  onFilterChange: (showFilteredAppointments: boolean) => void
  currentDate: DateTime,
}

const EyeExams = ({
  error,
  sortItem,
  filterItem,
  appointments,
  isAppointmentsLoading,
  confirmEyeExamFeatureEnabled,
  onFilterChange,
  currentDate,
}: EyeExamsTabProps) => {
  const dispatch = useDispatch();

  const [showFilteredAppointments, setShowFilteredAppointments] = useState(true);

  const handleSortChange = (selectedSortItem: SortItem) => {
    dispatch(updateAppointmentsSort(selectedSortItem));
  };

  useEffect(() => {
    if (confirmEyeExamFeatureEnabled) {
      dispatch(fetchAppointmentsList({
        page: INITIAL_PAGE,
        pageSize: PAGE_SIZE,
      }));
    }
  }, [filterItem, currentDate]);

  useEffect(() => {
    onFilterChange(showFilteredAppointments);
    dispatch(resetAppointmentsSort());
  }, []);

  const handleAppointmentViewClick = () => {
    setShowFilteredAppointments((prevState) => {
      onFilterChange(!prevState);
      return !prevState;
    });
  };

  const filteredAppointments = useMemo(() =>
    filterAppointmentsByTime(appointments.data), [appointments.data]);

  const filterButtonText = showFilteredAppointments ? 'Show All' : 'Show Upcoming';

  const getContent = () => {
    if (isAppointmentsLoading) {
      return <Spinner />;
    }

    if (appointments.data.length === 0) {
      return <EmptyListMessage>— No booked exams —</EmptyListMessage>;
    }

    const data = confirmEyeExamFeatureEnabled || !showFilteredAppointments
      ? appointments.data
      : filteredAppointments;

    return (
      <AppointmentList
        data={data}
        pageCount={!showFilteredAppointments ? appointments.pageCount : null}
        page={!showFilteredAppointments ? appointments.page : null}
        confirmEyeExamFeatureEnabled={confirmEyeExamFeatureEnabled}
        onToggleAppointmentsView={handleAppointmentViewClick}
        filterButtonText={filterButtonText}
        onSortChange={handleSortChange}
        sortItem={sortItem}
        pageSize={50}
      />
    );
  };

  return (
    <Container>
      <FeatureFlagged feature={CONFIRM_EYE_EXAM_BOOKING_FEATURE_FLAG}>
        <DataViewToolbar
          onSortChange={handleSortChange}
          filterItem={filterItem}
          sortItem={sortItem}
        />
      </FeatureFlagged>
      <>
        {error && <ErrorBlock error={error} />}
        {getContent()}
      </>
    </Container>
  );
};

const select = (state) => ({
  sortItem: state.eyeExams.appointments.sortItem,
  filterItem: state.eyeExams.appointments.filterItem,
  currentDate: state.eyeExams.appointments.currentDate,
});

const EyeExamsTab = connect(select)(EyeExams);

export default EyeExamsTab;
